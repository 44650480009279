import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserPermission } from '../user-permissions';

export const needPermissionGuard =
  (permission: UserPermission): CanActivateFn =>
  () => {
    const router = inject(Router);

    return (
      inject(AuthService).currentUserHasPermission(permission) ||
      router.parseUrl('/')
    );
  };
